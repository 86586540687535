// Global
import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';

// Lib
import { SitecorePageProps } from 'lib/page-props';

// Local
import 'assets/styles/global.css';
import Bootstrap from 'src/Bootstrap';

// It won't merge
import { defaultConfig } from 'tailwind-variants';
import { GlobalProvider } from 'helpers/GlobalContext/globalContext';
defaultConfig.twMerge = false;

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;

  return (
    // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
    // Note Next.js does not (currently) provide anything for translation, only i18n routing.
    // If your app is not multilingual, next-localization and references to it can be removed.
    <>
      <Bootstrap {...pageProps} />
      <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
        <GlobalProvider>
          <Component {...rest} />
        </GlobalProvider>
      </I18nProvider>
    </>
  );
}

export default App;
