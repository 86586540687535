import { Context, createContext, useState } from 'react';

type GlobalContextProps = {
  children?: React.ReactNode;
  defaultForecastData?: ForeCastResultData; // This is added for storybook visibility
};

type GlobalContextModel = null | {
  forecastData: ForeCastResultData;
  setForecastData: (val: ForeCastResultData) => void;
  locationSearchData: LocationSearchData;
  setLocationSearchData: (val: LocationSearchData) => void;
};

type ForeCastResultData = {
  validTimeUtc?: number[];
  dayOfWeek?: string[];
  indexValue?: number[];
  rawIndexValue?: number[];
  indexCategory?: string[];
};
export type LocationSearchData = {
  address?: string;
  latitude?: string;
  longitude?: string;
  postalCode?: number;
  displayName?: string;
  placeId?: string;
};

const GlobalContext: Context<GlobalContextModel> = createContext<GlobalContextModel>(null);

const GlobalProvider = ({ children, defaultForecastData }: GlobalContextProps) => {
  const [forecastData, setForecastData] = useState<ForeCastResultData>(defaultForecastData || {});
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData>({});

  const context = {
    forecastData,
    setForecastData,
    locationSearchData,
    setLocationSearchData,
  };

  return <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>;
};

export { GlobalProvider, GlobalContext };
